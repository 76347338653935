import { EQueryParam } from 'shared/enums/queryParam.enum';

export const WHITELIST_QUERY_PARAMS: Array<string> = [
  EQueryParam.Auth,
  EQueryParam.SamlSsoCode,
  EQueryParam.SamlSsoSessionState,
  EQueryParam.Search,
  EQueryParam.CategoryId,
  EQueryParam.Sort,
  EQueryParam.Page,
  EQueryParam.Filter,
];
