import { LocationQuery } from 'vue-router';
import { WHITELIST_QUERY_PARAMS } from 'constants/whitelistQueryParams.const';

export async function useRedirectMirror(): Promise<void> {
  const route = useRoute();
  const router = useRouter();

  async function clearQueryParams(query: LocationQuery, server = false): Promise<void> {
    const extraQueryKey = existsExtraQueryKeys(Object.keys(query));
    if (!extraQueryKey) {
      return;
    }

    const clearedParams = getClearedQueryParams<LocationQuery>(Object.entries(query));

    if (server) {
      await navigateTo({
        query: clearedParams,
        replace: true,
      }, {
        replace: true,
        redirectCode: 301,
      });
      return;
    }

    await router.replace({
      query: clearedParams,
    });
  }

  function getClearedQueryParams<T>(query: Array<[string, unknown]>): T {
    return query
      .filter(([queryKey]) => WHITELIST_QUERY_PARAMS.includes(queryKey))
      .filter(([, keyValue]) => keyValue)
      .reduce<T>((queryObj, [queryKey, keyValue]) => ({
        ...queryObj,
        [queryKey]: keyValue,
      }), {} as T);
  }

  function existsExtraQueryKeys(queryKeys: Array<string>): boolean {
    return queryKeys.some((queryKey) => !WHITELIST_QUERY_PARAMS.includes(queryKey));
  }

  if (process.server) {
    await clearQueryParams(route.query, true);
    return;
  }

  watch(
    () => route.query,
    (newQuery) => clearQueryParams(newQuery),
    { immediate: true, flush: 'pre' },
  );
}
